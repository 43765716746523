import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  header: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: colors.indigoCrush,
    zIndex: 100,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    transform: 'translate3d(0,0,0)',
    height: '100%',
  },

  header: {
    fontPlayfair: 40,
    lineHeight: '40px',
    marginVertical: 4,
    color: colors.white,
    textAlign: 'center',
    display: 'none',
  },
});

export default styles;
